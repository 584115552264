import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"assignBdoCardForm",staticClass:"custom-form",attrs:{"id":"assignBdoCardForm"},on:{"submit":function($event){$event.preventDefault();return _vm.assign.apply(null, arguments)}}},[_c(VCol,{staticClass:"d-flex flex-row-reverse"},[_c(VBtn,{staticClass:"base-hover mb-2",attrs:{"color":"primary","type":"submit","form":"assignBdoCardForm","disabled":!_vm.selectedCardExistAndHasPdfFile,"loading":_vm.isProcessingAssignment}},[_vm._v(" Przypisz kartę ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"mb-6",attrs:{"placeholder":"Wpisz nazwę klienta","label":"Nazwa klienta","outlined":"","hide-details":"","clearable":""},on:{"input":_vm.handleSearch,"click:clear":_vm.handleSearch},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icon',{staticClass:"mr-2",attrs:{"name":"search","size":"small"}})]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c(VDataTable,{staticClass:"cards-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.parsedBdoCards,"items-per-page":-1,"loading":_vm.isProsessingBdoCards,"loading-text":"Ładowanie","item-class":function (item) { return item.id === _vm.bdoCardId && 'highlighted-row'; }},on:{"click:row":_vm.selectCard},scopedSlots:_vm._u([{key:"item.refresh",fn:function(ref){
var refreshItem = ref.item;
return [_c('td',[_c(VBtn,{attrs:{"elevation":"0","color":"transparent","size":"x-small","disabled":_vm.isProsessingBdoCards,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.fetchBdoPdfs(refreshItem.id)}}},[_c(VIcon,{attrs:{"color":"#2CB56B","size":"20"}},[_vm._v(" mdi-refresh ")])],1)],1)]}}],null,true)})],1),(_vm.pdfFileUrl)?_c('embed',{attrs:{"src":_vm.pdfFileUrl,"type":"application/pdf","width":"100%","height":"500px"}}):(_vm.bdoCardId)?_c('Warning',{staticClass:"ml-4",attrs:{"message":"Brak karty KPO/KPOK do wyświetlenia"}}):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }